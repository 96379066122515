import * as React from 'react'
import Layout from '../../components/layout'
import Search from "../../components/POC1";

const BlogPage = ({location}) => {
	return (
		<Layout pageTitle="Les recettes ChefClub">
			<Search
				pathname={location ? location.pathname : ""}
				hash={location ? location.hash : ""}
				search={location ? location.search : null}
			/>
		</Layout>
	)
}

export default BlogPage